import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss']
})

export class AlertModalComponent implements OnInit {
  options: AnimationOptions = {
    path: '/assets/animations/alert.json',
  };
  public title: string = '';
  public subtitle: string = '';
  public primaryButtonLabel: string = '';
  public secondaryButtonLabel: string = '';

  constructor(
    public dialogRef: MatDialogRef<AlertModalComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public datas: any) { }

  ngOnInit(): void {
    this.title = this.datas.title;
    this.subtitle = this.datas.subtitle;
    this.primaryButtonLabel = this.datas.primaryButtonLabel;
    this.secondaryButtonLabel = this.datas.secondaryButtonLabel;
  }

  public close(primaryButtonPressed: boolean) {
    this.dialogRef.close(primaryButtonPressed);
  }

}
