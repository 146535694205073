import { Environment } from './environment.interface';

let dynamicEnvironment: Environment;

/**
 * Récupère l'environnement depuis l'api en déterminant la bonne url back
 * et défini les variables qui peuvent être calculées depuis le front
 * @returns Promise<Partial<Environment>>
 */
export async function getDynamicEnvironment(): Promise<Partial<Environment>> {
	const originUrl = window.location.origin;
	let apiUrl = originUrl + '/api';

	if (originUrl === 'https://ngserve.coortyassur.fr') {
		apiUrl = 'https://api.dev.coortyassur.fr/api';
	}

	if (dynamicEnvironment === undefined) {
		const env = await getEnvironmentFromApi(apiUrl, originUrl);
		dynamicEnvironment = env as Environment;
	}

	return {
		appUrl: originUrl,
		apiCoortyassurUrl: apiUrl,
		urlImpression: originUrl + '/impression',
		...dynamicEnvironment
	}
}

/**
 * Requête le endpoint "/environment" sur l'url apiUrl en passant l'url du front
 * @param apiUrl url de l'api à appeler
 * @param frontUrl url du front appelant
 * @returns Promise<Partial<Environment>>
 */
export async function getEnvironmentFromApi(apiUrl: string, frontUrl: string): Promise<Partial<Environment>> {
	return new Promise((resolve, reject) => {
		const request = new XMLHttpRequest();
		request.open('POST', apiUrl + '/environment');
		request.onload = () => {
			resolve(JSON.parse(request.response));
		}
		request.send(JSON.stringify({ frontUrl: frontUrl }));
	});
}
