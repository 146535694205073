import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppelsScoresMatProgressBarComponent } from './appels-scores-mat-progress-bar.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';


@NgModule({
  imports: [
    CommonModule,
    MatProgressBarModule
  ],
  declarations: [
    AppelsScoresMatProgressBarComponent
  ],
  exports: [
    AppelsScoresMatProgressBarComponent
  ]
})
export class AppelsScoresMatProgressBarModule { }
