import { I } from '@angular/cdk/keycodes';
import { Injectable, EventEmitter, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Subscription, BehaviorSubject } from 'rxjs';
@Injectable({
	providedIn: 'root'
})
export class RouterService implements OnDestroy {
	public routingDatasSubject = new BehaviorSubject<any[]>([])
	public routingParamsSubject = new BehaviorSubject<any[]>([])
	public routingDatas: any[] = [];
	public routingParams: any[] = [];
	private routerSubscription: Subscription;
	public routingHistory = [];

	constructor(public router: Router,
		public route: ActivatedRoute) {
		this.getDatas(this.route.snapshot);
		this.routerSubscription = this.router.events.subscribe((val) => {
			if (val instanceof NavigationEnd) {
				this.routingHistory.push(this.router.url);
				this.getDatas(route.snapshot);
			}
		});
	}

	private getDatas(snapshot): void {
		this.routingDatas = [];
		this.routingParams = [];
		let currentChild = snapshot.firstChild;
		while (currentChild && currentChild.firstChild) {
			currentChild = currentChild.firstChild;
			if (currentChild.data && Object.keys(currentChild.data).length > 0) {
				this.routingDatas.push(currentChild.data);
			}
			if (currentChild.params && Object.keys(currentChild.params).length > 0) {
				this.routingParams.push(currentChild.params);
			}
		}
		this.routingDatasSubject.next(this.routingDatas.flat());
		this.routingParamsSubject.next(this.routingParams.flat());
	}

	public getRoutingDatasProperty(property: string): any {
		return this.routingDatas.flat().reduce((acc, curr) => {
			if (curr[property]) {
				return [...acc, curr[property]];
			} else {
				return acc;
			}
		}, []).pop();
	}

	public getRoutingParamsProperty(property: string): any {
		return this.routingParams.flat().reduce((acc, curr) => {
			if (curr[property]) {
				return [...acc, curr[property]];
			} else {
				return acc;
			}
		}, []).pop();
	}

	ngOnDestroy(): void {
		this.routerSubscription.unsubscribe();
	}
}
