
import { Injectable } from '@angular/core';

@Injectable()
export class IconsService {
	getIcons() {
		const ICONS: Object = {
			'application/pdf': {
				'icon': 'fas fa-file-pdf',
				'color': '#d7090b',
			},
			'application/zip': {
				'icon': 'fas fa-file-archive',
				'color': '#f3be3c',
			},
			'application/gzip': {
				'icon': 'fas fa-file-archive',
				'color': '#f3be3c',
			},
			'application/xml': {
				'icon': 'fas fa-file-code',
				'color': '#ef7502',
			},
			'application/vnd.ms-excel': {
				'icon': 'fas fa-file-excel',
				'color': '#206f44',
			},
			'application/msword': {
				'icon': 'fas fa-file-word',
				'color': '#295496',
			},

			'audio/mpeg': {
				'icon': 'fas fa-file-audio',
				'color': '#49dbb6',
			},
			'image/gif': {
				'icon': 'fas fa-file-image',
				'color': '#297e95',
			},
			'image/jpeg': {
				'icon': 'fas fa-file-image',
				'color': '#297e95',
			},
			'image/png': {
				'icon': 'fas fa-file-image',
				'color': '#297e95',
			},

			'text/csv': {
				'icon': 'fas fa-file-csv',
				'color': '#4cb5ab',
			},
			'text/xml': {
				'icon': 'fas fa-file-code',
				'color': '#ef7502',
			},
			'text/plain': {
				'icon': 'fas fa-file-alt',
				'color': '#323232',
			},

			'video/mpeg': {
				'icon': 'fas fa-file-video',
				'color': '#d72b27',
			},
			'text/mp4': {
				'icon': 'fas fa-file-video',
				'color': '#d72b27',
			},
		}

		return ICONS;
	}
}

