import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

export interface SelectOption {
  libelle: string;
  id: number;
}

@Injectable({
  providedIn: 'root'
})

export class SelectOptionsService {
  public baseUrl: String = environment.apiCoortyassurUrl + '/';
  public listingPeriodiciteSubject = new BehaviorSubject<any>(null);
  public listingAssuranceSubject = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient) {
    this.getListingPeriodicite().subscribe(listingPeriodicite => {
      this.listingPeriodiciteSubject.next(listingPeriodicite);
    });

  }
  public getListingPeriodicite() {
    return this.http.get<any>(this.baseUrl + 'periodicite');
  }

  get listingPeriodiciteValue(): any {
    return this.listingPeriodiciteSubject.getValue();
  }
}
