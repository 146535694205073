import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from '../../../authentification/shared/services/auth.service';
import { of, Observable } from 'rxjs';
import 'rxjs/add/observable/of';
import { switchMap } from 'rxjs/operators';
import { USER_TYPE_EXTRANET } from 'app/extranet/shared/services/user/user.service';
import { USER_TYPE_EXTRANET_PRO } from 'app/extranet-pro/shared/services/core/user.service';

@Injectable()
export class AuthGuard implements CanActivate {

	constructor(
		private authService: AuthService,
		private _router: Router
	) { }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		if(this.authService.isAuthenticated()) {
			return this.authService.getUser().pipe(
				switchMap((user) => {
					if(!user) {
						return this.notAuthenticated();
					}
					let typesArray: number[] = user?.types.map((type) => type.id);
					let cabinetSlug = user.cabinets[0].slug;
					if (this.checkIfUserIsAllowedToAccess(typesArray, 'extranet')) {
						this._router.navigate(['extranet/' + cabinetSlug ]);
						return of(false);
					} else if (this.checkIfUserIsAllowedToAccess(typesArray, 'extranet-pro')) {
						this._router.navigate(['extranet-pro/' + cabinetSlug ]);
						return of(false);
					}
					else return of(true);
				})
			);
		} else {
			return this.notAuthenticated();
		}
	}

	private notAuthenticated(): Observable<false> {
		this._router.navigate(['auth/login']);
		return of(false);
	}

	public checkIfUserIsAllowedToAccess(types: number[], name: 'extranet' | 'extranet-pro'): boolean {
		let smallestType: number = types.reduce((a, b) => Math.min(a, b));
		if(name === 'extranet' && smallestType === USER_TYPE_EXTRANET) return true;
		else if(name === 'extranet-pro' && smallestType === USER_TYPE_EXTRANET_PRO) return true;
		else return false;
	}
}
