import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CabinetExtranetService } from '../services/cabinet.service';
import { ExtranetRoutingService } from '../services/extranet-routing.service';

@Injectable({
  providedIn: 'root'
})
export class RedirectionExtranetGuard implements CanActivate {

	constructor(
		private _router: Router,
		private _cabinetService: CabinetExtranetService,
		private _routingService: ExtranetRoutingService
	) {}

	canActivate(next: ActivatedRouteSnapshot): Observable<boolean> {
		let cabinetSlug: string = next.params.cabinet?.toLowerCase();

		if(cabinetSlug) this._routingService.baseUrl = 'extranet/' + cabinetSlug;
		else cabinetSlug = this._cabinetService.getCabinetSlug();


		return this._cabinetService.isAllowedToExtranet(cabinetSlug).pipe(
			catchError(() => {
				return this.notAllowed();
			})
		);
	}

	private notAllowed(): Observable<false> {
		this._router.navigate(['auth/login']);
		return of(false);
	}

}
