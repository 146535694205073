import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CabinetExtranetProService } from '../services/core/cabinet.service';

@Injectable({
  providedIn: 'root'
})
export class AccessExtranetProGuard implements CanActivate {

	private cabinetSlug: string = '';

	constructor(
		private _router: Router,
		private _cabinetService: CabinetExtranetProService,
	) {}

	canActivate(next: ActivatedRouteSnapshot): Observable<boolean> {
		if(next.params.cabinet) {
			this.cabinetSlug = next.params.cabinet.toLowerCase().replaceAll(' ', '');
			return this._cabinetService.isAllowedToExtranetPro(this.cabinetSlug).pipe(
				catchError(() => {
					return this.notAllowed();
				})
			);
		} else {
			return this.notAllowed();
		}
	}

	private notAllowed(): Observable<false> {
		this._router.navigate(['auth/login']);
		return of(false);
	}

}
