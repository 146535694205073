import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-appels-scores-mat-progress-bar',
  templateUrl: './appels-scores-mat-progress-bar.component.html',
  styleUrls: ['./appels-scores-mat-progress-bar.component.scss']
})
export class AppelsScoresMatProgressBarComponent implements OnInit {
  @Input() value: number;
  @Input() color: any;

  constructor() { }

  ngOnInit() {
  }


}
