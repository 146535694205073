import { Injectable } from '@angular/core';
import { IdCabinetsList } from 'app/coortyapp/shared/enum/cabinet/id-cabinets-list.enum';
import { CabinetsExtranetService } from 'app/core/services/extranet/cabinets-extranet.service';
import { log } from 'console';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ColorExtranetService } from './color.service';
import { ExtranetRoutingService } from './extranet-routing.service';
import { TypoExtranetService } from './typo.service';

@Injectable({
	providedIn: 'root'
})
export class CabinetExtranetService {

	private logo$: BehaviorSubject<string> = new BehaviorSubject('assets/images/logo.jpg');
	private name$: BehaviorSubject<string> = new BehaviorSubject('');
	private id$: BehaviorSubject<number> = new BehaviorSubject(1);
	private slug$: BehaviorSubject<string> = new BehaviorSubject('');

	constructor(
		private _colorService: ColorExtranetService,
		private _typoService: TypoExtranetService,
		private _cabinetsExtranetService: CabinetsExtranetService,
		private _routingService: ExtranetRoutingService
	) { }

	// Set all cabinet's colors and typos in the CSS
	public getCabinetExtranetDetails(): void {
		this._colorService.getExtranetColors();
		this._typoService.getExtranetTypos();
	}

	public setCabinetExtranetDetails(primaryColor: string, secondaryColor: string, primaryTypo: string, secondaryTypo: string): void {
		this._colorService.setExtranetColors(primaryColor, secondaryColor);
		this._typoService.setExtranetTypos(primaryTypo, secondaryTypo);
	}

	public getCabinetLogo(): string {
		return this.logo$.value;
	}

	public setCabinetLogo(logo: string): void {
		this.logo$.next(logo);
	}

	public getCabinetName(): string {
		return this.name$.value;
	}

	public setCabinetName(name: string): void {
		this.name$.next(name);
	}

	public getCabinetId(): number {
		return this.id$.value;
	}

	public setCabinetId(id: number): void {
		this.id$.next(id);
	}

	public getCabinetSlug(): string {
		return this.slug$.value;
	}

	public setCabinetSlug(slug: any = ''): void {
		let formattedSlug: string = slug.toLowerCase().replaceAll(' ', '');
		this.slug$.next(formattedSlug);
	}

	public isAllowedToExtranet(cabinetSlug: any): Observable<boolean> {
		
		let formattedSlug: string = cabinetSlug?.toLowerCase().replaceAll(' ', '');

		return this._cabinetsExtranetService.getCabinetsOpenToExtranet().pipe(
			switchMap((listCabinets: any[]) => {
				let cabinetAllowed = listCabinets.find((cabinet) => cabinet.slug === formattedSlug && cabinet.droitExtranet === true);
				let colorPrimary = cabinetAllowed.colorPrimary ? cabinetAllowed.colorPrimary : '1, 1, 1';
				let colorSecondary = cabinetAllowed.colorSecondary ? cabinetAllowed.colorSecondary : '1, 1, 1';

				if (cabinetAllowed) {
					this.setCabinetExtranetDetails(
						colorPrimary,
						colorSecondary,
						cabinetAllowed.policePrimary,
						cabinetAllowed.policeSecondary
					);
					this.setCabinetLogo(cabinetAllowed?.documentServeur?.chemin);
					this.setCabinetName(cabinetAllowed.nom);
					this.setCabinetId(cabinetAllowed.id);
					this.setCabinetSlug(cabinetAllowed.slug);
				};

				return cabinetAllowed ? of(true) : of(false);
			})
		);
	}

	// CABINETS SPE

	public isCabinetYeetVtc(): boolean {
		return this.getCabinetId() === IdCabinetsList.YEET_VTC;
	}

	public isCabinetKolibri(): boolean {
		return this.getCabinetId() === IdCabinetsList.KOLIBRI;
	}

	public isCabinetSpecial(): boolean {
		let cabinetsSpeArray: number[] = [
			IdCabinetsList.YEET_VTC,
			IdCabinetsList.KOLIBRI
		];

		return cabinetsSpeArray.includes(this.getCabinetId());
	}
}
