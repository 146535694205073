import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GedContactDirective } from './ged-contact.directive';

@NgModule({
	declarations: [
		GedContactDirective
	],
	imports: [
		CommonModule,
	],
	exports: [
        GedContactDirective
	],

})
export class GedDirectiveModule { }
