<div class="p-2 d-flex flex-column justify-content-between" style="height: 100%">
	<div class="d-flex justify-content-center">
		<ng-lottie width="150px" height="150px" [options]="options"></ng-lottie>
	</div>
	<div style="text-align: center;">
		<h4 class="black bold mt-1"> {{ title }} </h4>
		<h5 class="grey mb-0 mt-1" *ngIf="subtitle">{{ subtitle }}</h5>
		<div class="mt-1">
			<button mat-stroked-button *ngIf="secondaryButtonLabel" (click)="close()"
				class="grey bold mx-1">{{ secondaryButtonLabel }}</button>
			<button mat-flat-button class="bg-material-blue white bold mx-1"
				(click)="close(true)" *ngIf="primaryButtonLabel">{{ primaryButtonLabel }}</button>
		</div>
	</div>
</div>
