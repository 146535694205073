import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyFilterPipe } from './filter-pipe';
import { FileSizePipe } from './file-size-pipe';
import { MomentPipe } from './moment-pipe';
import { sanitizeHtmlPipe } from './html-pipe';
import { MyFilterTemplatePipe } from './filter-pipe-template';
import { CustomCurrencyPipe } from './custom-currency-pipe';
import { flotteTablePipe } from '../../contact/modal-contact-detail/contact-onglets/decouverte-contact/decouverte-detail/flotte/flotte-table/flotte-table.pipe'
import { GedSizePipe } from 'app/extranet-pro/modules/contrat-listing/detail-contrat/ged-documents-list/ged.pipe';
import { ActesSizePipe } from 'app/extranet-pro/modules/sinistre-listing/detail-sinistre/gli-sinistre/gli-actes-sinistre/gli-actes-sinsitre.pipe';
import { dateFormatPipe } from 'app/extranet-pro/modules/contrat-listing/detail-contrat/detail-contrat-infos/date.pipe';
import { NumberDecimalPipe } from './number.pipe';
import { dateToStringPipe } from './date-string-pipe';



@NgModule({
	declarations: [
		MyFilterPipe,
		FileSizePipe,
		MomentPipe,
		sanitizeHtmlPipe,
		MyFilterTemplatePipe,
		CustomCurrencyPipe,
		flotteTablePipe,
		GedSizePipe,
		ActesSizePipe,
		dateFormatPipe,
		NumberDecimalPipe,
		dateToStringPipe
	],
	exports: [
		MyFilterPipe,
		FileSizePipe,
		MomentPipe,
		sanitizeHtmlPipe,
		MyFilterTemplatePipe,
		CustomCurrencyPipe,
		flotteTablePipe,
		GedSizePipe,
		ActesSizePipe,
		dateFormatPipe,
		NumberDecimalPipe,
		dateToStringPipe
	],
	imports: [
		CommonModule
	]
})
export class PipesModule { }
