import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

interface CabinetExtranetInformations {
	id: number
	nom: string
	colorPrimary: string
	colorSecondary: string
	policePrimary: string
	policeSecondary: string
	logo: string
	slug: string
}


@Injectable({
 	providedIn: 'root'
})
export class CabinetsExtranetService {

	private _allowedCabinetsToExtranet$: BehaviorSubject<CabinetExtranetInformations[]> = new BehaviorSubject([]);

	constructor(
		private _http: HttpClient,
	) {}

	public get allowedCabinetsToExtranet(): CabinetExtranetInformations[] {
		return this._allowedCabinetsToExtranet$.value;
	}

	public set allowedCabinetsToExtranet(list: CabinetExtranetInformations[]) {
		this._allowedCabinetsToExtranet$.next(list);
	}

	public getCabinetsOpenToExtranet(): Observable<CabinetExtranetInformations[]> {
		if(this.allowedCabinetsToExtranet.length === 0) return this._http.get<CabinetExtranetInformations[]>(`${environment.apiCoortyassurUrl}/partenaire/cabinets`).pipe(
			tap((cabinetsList) => {
				this.allowedCabinetsToExtranet = cabinetsList;
			})
		);
		else return of(this.allowedCabinetsToExtranet) ;
	}
}
