import { NgModule, CUSTOM_ELEMENTS_SCHEMA, Injector, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from 'environments/environment';

/* Routing Module */
import { AppRoutes } from './app.routing';

/* App Root */
import { AppComponent } from './app.component';

/* Core Modules */
import { CoreModule } from './core/core.module';

/* Date */
import { MAT_DATE_LOCALE, MAT_DATE_FORMATS, DateAdapter } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter'
/* Theme Service */
import { ThemeService } from './coortyapp/shared/services/theme.service';


/* Components App Modules */
import { NgxContentLoadingModule } from 'ngx-content-loading';
import { CookieService, CookieOptions } from 'angular2-cookie/core';
import { ColorsAgendaSharedService } from './coortyapp/shared/models/colors-shared-agenda';
// import { MatomoModule } from 'ngx-matomo';
import { registerLocaleData, CommonModule } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { FacadeService } from './core/services/facade.service';
import { SelectOptionsService } from './core/services/selectOptions.service';
import { RouterService } from './core/services/router.service';
import { HttpClientModule } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { IconsService } from './coortyapp/shared/models/icons';
import { HoursEventService } from './coortyapp/shared/models/list-hours-event';
import { DaysService } from './coortyapp/shared/models/days';
import { HoursService } from './coortyapp/shared/models/list-hours';
import { MinutesService } from './coortyapp/shared/models/list-minutes';
import { SharedModule } from './coortyapp/shared/shared.module';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { NgxCaptchaModule } from 'ngx-captcha';
import {MatFormFieldModule} from '@angular/material/form-field';

// Authentification Google, Apple, AuthO
import { GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from 'angularx-social-login';
import { AuthModule } from '@auth0/auth0-angular';
import { ServiceLocator } from './coortyapp/shared/services/locator.service';
import { AppInitService } from './core/app-init.service';

registerLocaleData(localeFr);

export function playerFactory() {
	return player;
};

export const MY_FORMATS = {
	parse: {
		dateInput: 'DD/MM/YYYY',
	},
	display: {
		dateInput: 'DD/MM/YYYY',
		monthYearLabel: 'MM YYYY',
		dateA11yLabel: 'DD/MM/YYYY',
		monthYearA11yLabel: 'MM YYYY',
	},
};

export function initApp(appInitializer: AppInitService) {
	return () => appInitializer.init();
}

export let isProd = false;
if (environment.env === 'LTS') {
	isProd = true;
}

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		CommonModule,
		SharedModule,
		// MatMomentDateModule,
		CoreModule,
		MatDialogModule,
		NgxContentLoadingModule,
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		AppRoutes,
		LottieModule.forRoot({ player: playerFactory }),
		// MatomoModule,
		NgxCaptchaModule,
		MatFormFieldModule,

		// Registration Google, Apple and AuthO
		SocialLoginModule,
		AuthModule.forRoot({
			domain: environment.authO.oAuthDomain,
			clientId: environment.authO.oAuthIdClient
		}),
	],
	providers: [
		AppInitService,
		{
			provide: APP_INITIALIZER,
			useFactory: initApp,
			deps: [AppInitService],
			multi: true
		},
		FacadeService,
		SelectOptionsService,
		RouterService,
		{ provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
		{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
		{ provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
		{ provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
		IconsService,
		HoursEventService,
		DaysService,
		HoursService,
		MinutesService,
		ColorsAgendaSharedService,
		CookieService,
		{ provide: CookieOptions, useValue: {} },
		ThemeService,

		// Registration Google
		{
			provide: 'SocialAuthServiceConfig',
			useValue: {
			autoLogin: false,
			providers: [
				{
					id: GoogleLoginProvider.PROVIDER_ID,
					provider: new GoogleLoginProvider(
						environment.googleOAuthIdClient,
						{ scope: 'profile email' }
					)
				},
			]
			} as SocialAuthServiceConfig,
		}

	],
	bootstrap: [AppComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	entryComponents: []
})
export class AppModule {
	constructor(private injector: Injector) {
		ServiceLocator.injector = injector;
	}
}
