import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-alert-modal-libelle-contre-passation',
  templateUrl: './alert-modal-libelle-contre-passation.component.html',
  styleUrls: ['./alert-modal-libelle-contre-passation.component.scss']
})
export class AlertModalLibelleContrePassationComponent implements OnInit {

  options: AnimationOptions = {
    path: '/assets/animations/alert.json',
  };
  public title: string;
  public subtitle: string;
  public primaryButtonLabel: string;
  public secondaryButtonLabel: string;
  public motifContrePassation: string;
  public valueMotifContrePassation: string;

  constructor(
    public dialogRef: MatDialogRef<AlertModalLibelleContrePassationComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public datas: any) { }

  ngOnInit(): void {
    this.title = this.datas.title || '';
    this.subtitle = this.datas.subtitle;
    this.primaryButtonLabel = this.datas.primaryButtonLabel;
    this.secondaryButtonLabel = this.datas.secondaryButtonLabel;
    /* this.motifContrePassation = this.datas.motifContrePassation; */
  }

  public onAddMotifContrePassation(event) {
    this.valueMotifContrePassation = event.target.value;
  }

  public close(primaryButtonPressed: boolean, motifContrePassation: string) {
    let dataAfterClose = { primaryButtonPressed, motifContrePassation }
    this.dialogRef.close(dataAfterClose)
  }

}
