import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/* Services Core Module */
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthService } from '../authentification/shared/services/auth.service';
import { AuthGuard } from './services/authguard/auth-guard.service'
import { AntiAuthGuard } from './services/authguard/anti-auth-guard.service'
import { TokenInterceptor } from './interceptor/token.interceptor'
import { RoleGuardService } from './services/authguard/role-guard.service';
import { AlertModalComponent } from 'app/coortyapp/shared/modals/alert-modal/alert-modal.component';
import { AlertModalModule } from 'app/coortyapp/shared/modals/alert-modal/alert-modal.module';

@NgModule({
	declarations: [
	],
	exports: [
	],
	imports: [
		CommonModule,
		AlertModalModule
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TokenInterceptor,
			multi: true
		},
		AuthService,
		AuthGuard,
		AntiAuthGuard,
		RoleGuardService
	],
	entryComponents: [
		AlertModalComponent
	]
})
export class CoreModule { }

