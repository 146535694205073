
import { Injectable } from '@angular/core';

@Injectable()
export class HoursService {
	getHours() {
		const HOURS: any = [
			{ libelle: '0 h', hour: 0 },
			{ libelle: '01 h', hour: 1 },
			{ libelle: '02 h', hour: 2 },
			{ libelle: '03 h', hour: 3 },
			{ libelle: '04 h', hour: 4 },
			{ libelle: '05 h', hour: 5 },
			{ libelle: '06 h', hour: 6 },
			{ libelle: '07 h', hour: 7 },
			{ libelle: '08 h', hour: 8 },
			{ libelle: '09 h', hour: 9 },
			{ libelle: '10 h', hour: 10 },
			{ libelle: '11 h', hour: 11 },
			{ libelle: '12 h', hour: 12 },
			{ libelle: '13 h', hour: 13 },
			{ libelle: '14 h', hour: 14 },
			{ libelle: '15 h', hour: 15 },
			{ libelle: '16 h', hour: 16 },
			{ libelle: '17 h', hour: 17 },
			{ libelle: '18 h', hour: 18 },
			{ libelle: '19 h', hour: 19 },
			{ libelle: '20 h', hour: 20 },
			{ libelle: '21 h', hour: 21 },
			{ libelle: '22 h', hour: 22 },
			{ libelle: '23 h', hour: 23 },
			{ libelle: '24 h', hour: 24 },
		]
		return HOURS;
	}
}

