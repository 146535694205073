import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'angular2-cookie';
import { delay } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { of as observableOf, BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class UserExtranetProService {

	private baseUrl: String = environment.apiCoortyassurUrl + '/';

	public userType$: BehaviorSubject<number> = new BehaviorSubject(null);
	public id$: BehaviorSubject<number> = new BehaviorSubject(null);
	public firstname$: BehaviorSubject<string> = new BehaviorSubject('');

	// From original Coorty
	static userSubject = new BehaviorSubject<any>(null);
	static userExtranetProSubject = new BehaviorSubject<any>(null);

	public user: any = null;
	public userExtranetPro: any = null;

	public roles: any = [];
	public authTokenNew: String = 'new';
	public currentToken: String = null;
	public refreshToken: String = null;

	constructor(
		private _http: HttpClient,
		private _router: Router,
		private _cookieService: CookieService
	) {
		// From original Coorty
		if (localStorage.getItem('jwt')) {
			this.currentToken = JSON.parse(localStorage.getItem('jwt')).token;
			this.refreshToken = JSON.parse(localStorage.getItem('jwt')).refresh_token;
		}
	}

	public getUserType(): number {
		return this.userType$.value;
	}

	public setUserType(type: number): void {
		this.userType$.next(type);
	}

	public getUserId(): number {
		return this.id$.value;
	}

	public setUserId(id: number): void {
		this.id$.next(id);
	}

	public getUserName(): string {
		return this.firstname$.value;
	}

	public setUserName(name: string): void {
		this.firstname$.next(name);
	}

	// --------------- METHODS FROM ORIGINAL COORTY -----------------------

	// Recupere le /me
	public getUser() {
		return Observable.from(this._http.get(this.baseUrl + 'utilisateur/moi')).do((res: any) => {
			UserExtranetProService.userSubject.next(res);
			this.user = res;
			this.roles = this.user.roles.map((prop) => {
				return prop.libelle;
			});
		});
	}

	// Recupere le /me
	public getUserExtranetPro(idUSer) {
		return this._http.get(this.baseUrl + 'utilisateurPro/utilisateur/' + `${idUSer}`).subscribe((res: any) => {
			UserExtranetProService.userExtranetProSubject.next(res);
			this.userExtranetPro = res;
		});
	}

	// Verifie l'authentification
	public isAuthenticated(): boolean {
		return this.currentToken ? true : false;
	}

	public logout() {
		// TO DO
		this.currentToken = null;
		localStorage.clear();
		sessionStorage.clear();
		this._cookieService.removeAll();
		window.location.reload();
		// this.router.navigate(['auth/login'])
	}

	// Recupere l'url avec l'id du mail qui a été envoyé par l'api
	public pirateAccount(token: String): Observable<String> {
		return this._http.get<String>(this.baseUrl + 'auth/motdepasse/pirate/' + token)
	}

	// Connexion + set token dans le Local Storage
	public signin(email: String, password: String, captcha: String): Observable<String> {    // captcha string
		const query = {
			'email': email,
			'mot_de_passe': password,
			'g-recaptcha-response': captcha,
		}
		return this._http.post<String>(this.baseUrl + 'auth/login_check', query).do((token: any) => {
			this.setTokens(token);
		});
	}

	// Renvoie sur le page mot de passe oublié
	public forgetPassword(email: String, captcha: string): Observable<String> {
		return this._http.post<String>(this.baseUrl + 'auth/motdepasse/perdu', { email, 'g-recaptcha': captcha });
	}

	// Création du nouveau password
	public newPassword(id: String, password: String, captcha: String): Observable<String> {
		const query = {
			'mot_de_passe': password,
			'g-recaptcha': captcha
		}
		return this._http.put<String>(this.baseUrl + 'auth/motdepasse/nouveau/' + id, query);
	}

	// Renvoie le token courant
	public getAuthToken() {
		return this.currentToken;
	}

	// Interceptor ******* // Recupere un nouveau token (refresh)
	public newRefreshToken(): Observable<String> {
		const query = {
			'refresh_token': this.refreshToken
		}
		this._http.post(this.baseUrl + 'auth/token/refresh', query)
			.subscribe((newtoken) => {
				this.setTokens(newtoken);
			})
		return observableOf(this.authTokenNew).pipe(delay(1000));
	}

	// Set tokens dans le Local Storage
	public setTokens(token) {
		localStorage.setItem('jwt', JSON.stringify(token));
		this.refreshToken = token.refresh_token;
		this.currentToken = token.token;
		sessionStorage.clear();
		this._cookieService.removeAll();
	}
}

export const USER_TYPE_EXTRANET_PRO = 20;
