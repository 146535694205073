import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';


import { ScrollingModule } from '@angular/cdk/scrolling';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { EventConcerneCreateModalComponent } from 'app/coortyapp/shared/modals/event-concerne-create-modal/event-concerne-create-modal.component';
import { EventContactCreateModalComponent } from 'app/coortyapp/shared/modals/event-contact-create-modal/event-contact-create-modal.component';
import { AvatarModule } from 'ngx-avatar';
import { LottieModule } from 'ngx-lottie';
import { QuillModule } from 'ngx-quill';
import Quill from 'quill';
import { SuffixCurrencyPipe } from './pipes/suffix-currency-pipe';

import { ImpayeCreateModalComponent } from 'app/coortyapp/shared/modals/impaye-create-modal/impaye-create-modal.component';
import { ModelesMailCreateComponent } from 'app/coortyapp/shared/modals/modeles-mail-create/modeles-mail-create.component';
import { ModelesMailViewerComponent } from 'app/coortyapp/shared/modals/modeles-mail-viewer/modeles-mail-viewer.component';
import { ModelesPDFCreateComponent } from 'app/coortyapp/shared/modals/modeles-pdf-create/modeles-pdf-create.component';
import { ModelesPDFViewerComponent } from 'app/coortyapp/shared/modals/modeles-pdf-viewer/modeles-pdf-viewer.component';
import { RubriqueUpdateModalComponent } from 'app/coortyapp/shared/modals/rubrique-update-modal/rubrique-update-modal.component';
import { RubriquesSelectionModalComponent } from 'app/coortyapp/shared/modals/rubriques-selection-modal/rubriques-selection-modal.component';
import { SucessModalComponent } from 'app/coortyapp/shared/modals/sucess-modal/sucess-modal.component';
import { UtilisateursSelectionModalComponent } from 'app/coortyapp/shared/modals/utilisateurs-selection-modal/utilisateurs-selection-modal.component';

import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { AppelsScoresMatProgressBarComponent } from '../gestion/appel-cotisation/appels-scores-mat-progress-bar/appels-scores-mat-progress-bar.component';
import { AppelsScoresMatProgressBarModule } from '../gestion/appel-cotisation/appels-scores-mat-progress-bar/appels-scores-mat-progress-bar.module';
import { DatepickerModule } from './components/datepicker/datepicker.module';
import { LoaderAnimModule } from './components/loader-anim/loader-anim.module';
import { MailEventOptimaxModule } from './components/mail-event-optimax/mail-event-optimax.module';
import { GedDirectiveModule } from './directives/ged/ged-directive.module';
import { PipesModule } from './pipes/pipes.module';
import { EventCreateModalComponent } from './modals/event-create-modal/event-create-modal.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { GarantiesSelectionModalComponent } from './modals/garanties-selection-modal/garanties-selection-modal.component';

var DirectionAttribute = Quill.import('attributors/attribute/direction');
Quill.register(DirectionAttribute, true);
var AlignStyle = Quill.import('attributors/style/align');
Quill.register(AlignStyle, true);
var BackgroundStyle = Quill.import('attributors/style/background');
Quill.register(BackgroundStyle, true);
var ColorStyle = Quill.import('attributors/style/color');
Quill.register(ColorStyle, true);
var DirectionStyle = Quill.import('attributors/style/direction');
Quill.register(DirectionStyle, true);
var FontStyle = Quill.import('attributors/style/font');
Quill.register(FontStyle, true);
var SizeStyle = Quill.import('attributors/style/size');
Quill.register(SizeStyle, true);

@NgModule({
	declarations: [
		RubriquesSelectionModalComponent,
		UtilisateursSelectionModalComponent,
		ImpayeCreateModalComponent,
		SucessModalComponent,
		RubriqueUpdateModalComponent,
		GarantiesSelectionModalComponent,
		SuffixCurrencyPipe,
		ModelesPDFViewerComponent,
		ModelesPDFCreateComponent,
		ModelesMailViewerComponent,
		ModelesMailCreateComponent,
		EventConcerneCreateModalComponent,
		EventContactCreateModalComponent,
		EventCreateModalComponent
	],
	imports: [
		CommonModule,
		MatButtonModule,
		MatSlideToggleModule,
		MatSelectModule,
		MatInputModule,
		MatChipsModule,
		MatProgressBarModule,
		PipesModule,
		MatRadioModule,
		QuillModule,
		MatDialogModule,
		NgSelectModule,
		ScrollingModule,
		MatDatepickerModule,
		MatIconModule,
		MatListModule,
		RouterModule,
		AvatarModule,
		FormsModule,
		ReactiveFormsModule,
		LottieModule,
		MatButtonToggleModule,
		LoaderAnimModule,
		MatTableModule,
		MatTooltipModule,
		MatTabsModule,
		GedDirectiveModule,
		MailEventOptimaxModule,
		DatepickerModule,
		AppelsScoresMatProgressBarModule,
		MatCardModule,
		MatButtonModule,
		MatExpansionModule,
		MatMenuModule,
		MatSnackBarModule,
		MatPaginatorModule,
		MatProgressSpinnerModule,
		
	],
	entryComponents: [
		AppelsScoresMatProgressBarComponent,
	],
	exports: [
		MatButtonModule,
		MatSelectModule,
		MatRadioModule,
		FormsModule,
		ReactiveFormsModule,
		MatIconModule,
		MatInputModule,
		MatProgressBarModule,
		NgSelectModule,
		RouterModule,
		RubriqueUpdateModalComponent,
		RubriquesSelectionModalComponent,
		GarantiesSelectionModalComponent,
		UtilisateursSelectionModalComponent,
		ImpayeCreateModalComponent,
		ModelesPDFViewerComponent,
		ModelesPDFCreateComponent,
		SucessModalComponent,
		SuffixCurrencyPipe,
		ModelesMailViewerComponent,
		ModelesMailCreateComponent,
		EventConcerneCreateModalComponent,
		EventContactCreateModalComponent,
		EventCreateModalComponent
	],
	providers: [
		{ provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
	],
})
export class SharedModule { }
