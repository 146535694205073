import { EventEmitter, Injectable } from '@angular/core';

@Injectable()
export class ThemeService {
	getTheme: boolean;
	themeColor: EventEmitter<any> = new EventEmitter();
	formValidEvent: EventEmitter<any> = new EventEmitter();
	tabEvent: EventEmitter<any> = new EventEmitter();
	// TO DO
	//tabContactDetailEvent: EventEmitter<any> = new EventEmitter();
}
