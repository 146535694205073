<div style="display: flex;">
  <mat-progress-bar 
    mode="determinate" 
    [value]="value" 
    [color]="color"
    class="progress">
  </mat-progress-bar>
  <span [ngStyle]="{'left': value + '%'}">
    {{ value }}%
  </span>
</div>
