import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class ExtranetRoutingService {

	private _baseUrl: string = null;

	public get baseUrl() {
		return this._baseUrl;
	}

	public set baseUrl(url: string) {
		this._baseUrl = url;
	}

	constructor() { }

}
