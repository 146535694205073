import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/services/authguard/auth-guard.service';
import { AntiAuthGuard } from './core/services/authguard/anti-auth-guard.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { UserResolver } from './coortyapp/shared/resolvers/me.resolver.service';
import { RedirectionExtranetGuard } from './extranet/shared/guards/redirection-extranet.guard';
import { AccessExtranetProGuard } from './extranet-pro/shared/guards/access-extranet-pro.guard';
import { DocumentEditorModule } from '@coorty/app/modules/document-editor/document-editor.module';

const routes: Routes = [
{
	path: 'erreur',
	loadChildren: () => import('./core/erreur/erreur.module').then(m => m.ErreurModule)
},
{
	path: 'auth',
	loadChildren: () => import('./authentification/authentification.module').then(m => m.AuthentificationModule),
	canActivate: [ AntiAuthGuard ]
},
{
	path: 'impression',
	loadChildren: () => import('app/coortyapp/impression/impression.module').then(m => m.ImpressionModule),
},

{
	path: 'document-editor',
	loadChildren: () => import('@coorty/app/modules/document-editor/document-editor.module').then(m => m.DocumentEditorModule),
},

//  EXTRANET PARTICULIER ET PROFESSIONNEL
{
	path: 'extranet-pro/:cabinet',
	loadChildren: () => import('app/extranet-pro/extranet-pro.module').then(m => m.ExtranetProModule),
	canActivate: [ AccessExtranetProGuard ]
},
{
	path: 'extranet/:cabinet',
	loadChildren: () => import('app/extranet/extranet.module').then(m => m.ExtranetModule),
	canActivate: [ RedirectionExtranetGuard ]
},
{
	path: 'extranet-pro',
 	redirectTo: 'erreur',
 	pathMatch: 'full'
},
{
	path: 'extranet',
	redirectTo: 'erreur',
	pathMatch: 'full'
},
// --------

{
	path: '',
	loadChildren: () => import('./coortyapp/coortyapp.module').then(m => m.CoortyAppModule),
	resolve: { user: UserResolver },
	canActivate: [ AuthGuard ]
}
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes),
		MatFormFieldModule
	],
	exports: [RouterModule],
	providers: [
		UserResolver,
		AuthGuard
	]
})

export class AppRoutes { }
