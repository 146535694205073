
import { Injectable } from '@angular/core';

@Injectable()
export class DaysService {
	getDays(daysofMonth) {
		const days = [];
		for (let i = 1; i < daysofMonth + 1; i += 1) {
			days.push({ libelle: `${i}`, id: i });
		}
		return days;
	}

	getMonths() {
		const MONTHS = [{ number: 1, libelle: 'Janvier', days: 31 },
		{ number: 2, libelle: 'Février', days: 28 },
		{ number: 3, libelle: 'Mars', days: 31 },
		{ number: 4, libelle: 'Avril', days: 30 },
		{ number: 5, libelle: 'Mai', days: 31 },
		{ number: 6, libelle: 'Juin', days: 30 },
		{ number: 7, libelle: 'Juillet', days: 31 },
		{ number: 8, libelle: 'Août', days: 31 },
		{ number: 9, libelle: 'Septembre', days: 30 },
		{ number: 10, libelle: 'Octobre', days: 31 },
		{ number: 11, libelle: 'Novembre', days: 30 },
		{ number: 12, libelle: 'Décembre', days: 31 }]
		return MONTHS;
	}

	getMonth(monthId) {
		return this.getMonths().find(month => month.number === monthId)
	}
}

