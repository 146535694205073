import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressBarModule }  from '@angular/material/progress-bar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';


import { MailEventOptimaxComponent } from './mail-event-optimax.component';
@NgModule({
	declarations: [
        MailEventOptimaxComponent
	],
	imports: [
		CommonModule,
		NgSelectModule,
        MatCardModule,
		MatListModule,
		MatButtonModule,
		MatExpansionModule,
		MatMenuModule,
		FormsModule,
		ReactiveFormsModule,
		MatProgressBarModule,
		MatSnackBarModule,
		MatPaginatorModule,
		MatProgressSpinnerModule
	],
	exports: [
		MailEventOptimaxComponent
	],
})
export class MailEventOptimaxModule { }
