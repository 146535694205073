import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ColorExtranetProService {

	public primaryColor$: BehaviorSubject<string> = new BehaviorSubject('234, 90, 54');
	public secondaryColor$: BehaviorSubject<string> = new BehaviorSubject('9, 51, 107');
	public tertiaryColor: string = '1, 1, 1';

	public getExtranetColors(): void {
		this.primaryColor$.subscribe((color) => {
			document.documentElement.style.setProperty('--primary-color', color);
		});
		this.secondaryColor$.subscribe((color) => {
			document.documentElement.style.setProperty('--secondary-color', color);
		});
		document.documentElement.style.setProperty('--tertiary-color', this.tertiaryColor);
	}

	public setExtranetColors(primaryColor: string, secondaryColor: string): void {
		this.primaryColor$.next(primaryColor);
		this.secondaryColor$.next(secondaryColor);
	}

	public changeHexaToRGB(hexa: string): string {
		let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexa);
		if(result){
			let red = parseInt(result[1], 16);
			let green = parseInt(result[2], 16);
			let blue = parseInt(result[3], 16);
			return `${red}, ${green}, ${blue}`;
		}
		return null;
	}

	public changeRGBToHexa(code: string) {
		let colorsTable = code.split(', ');

		let red: number = +colorsTable[0];
		let green: number = +colorsTable[1];
		let blue: number = +colorsTable[2];

		return "#" + ((1 << 24) + (red << 16) + (green << 8) + blue).toString(16).slice(1);
	  }
}
