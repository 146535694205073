import { Injectable, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from '../../authentification/shared/services/auth.service';
import { CabinetService } from '../../coortyapp/shared/services/cabinet.service';
import { CabinetListModel } from '../../coortyapp/shared/services/cabinet.service';
import { AlertModalComponent } from '../../coortyapp/shared/modals/alert-modal/alert-modal.component';
import { AlertModalLibelleContrePassationComponent } from 'app/coortyapp/gestion/comptes/compte-detail/alert-modal-libelle-contre-passation/alert-modal-libelle-contre-passation.component';

@Injectable({
	providedIn: 'root'
})
export class FacadeService {

	public isOverlayDisplayEmitter = new EventEmitter(true);
	public cabinetsList = new BehaviorSubject<CabinetListModel[]>([]);
	public selectedCabinet = new BehaviorSubject<CabinetListModel>(null);
	public selectedCabinets = new BehaviorSubject<CabinetListModel[]>([]);
	public selectedUsers = new BehaviorSubject<[]>([]);

	constructor(
		private cabinetsService: CabinetService,
		private dialog: MatDialog
	) {
		this.cabinetsService.getAllCabinet().subscribe(cabinets => {
			this.cabinetsList.next(cabinets);
			const user = AuthService.userSubject.getValue();
			const cabinetFavoriId = user?.cabinet_favori?.id;
			const cabinetFavori = this.cabinetsList.getValue().find((currentCabinet) => currentCabinet.cabinet_id == cabinetFavoriId);
			this.selectedCabinet.next(cabinetFavori);
			this.selectedCabinets.next([cabinetFavori]);
		});
	}

	get selectedCabinetValue(): CabinetListModel {
		return this.selectedCabinet.getValue();
	}

	public openAlertModal(title: string, subtitle: string, primaryButtonLabel?: string, secondaryButtonLabel?: string): MatDialogRef<AlertModalComponent> {
		return this.dialog.open(AlertModalComponent, {
			disableClose: false,
			height: '450px',
			width: '450px',
			data: {
				title,
				subtitle,
				primaryButtonLabel,
				secondaryButtonLabel
			}
		})
	}

	public virguleToPoint(maybeString: any): any {
		const isString = (maybeString && typeof maybeString === 'string');
		return (isString ? maybeString.replace(',', '.') : maybeString)
	}

	public openAlertModalLibelleContrePassation(title: string, subtitle: string, primaryButtonLabel: string, secondaryButtonLabel?: string, motifContrePassation?: string): MatDialogRef<AlertModalLibelleContrePassationComponent> {
		return this.dialog.open(AlertModalLibelleContrePassationComponent, {
			disableClose: false,
			height: '450px',
			width: '450px',
			data: {
				title,
				subtitle,
				primaryButtonLabel,
				secondaryButtonLabel,
				motifContrePassation
			}
		})
	}

}
