import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class TypoExtranetProService {

	public primaryTypo$: BehaviorSubject<string> = new BehaviorSubject('Roboto');
	public secondaryTypo$: BehaviorSubject<string> = new BehaviorSubject('Roboto');

	public getExtranetTypos(): void {
		this.primaryTypo$.subscribe((typo) => {
			document.documentElement.style.setProperty('--primary-typo', typo);
		});
		this.secondaryTypo$.subscribe((typo) => {
			document.documentElement.style.setProperty('--secondary-typo', typo);
		});
	}

	public setExtranetTypos(primaryTypo: string, secondaryTypo: string): void {
		this.primaryTypo$.next(primaryTypo);
		this.secondaryTypo$.next(secondaryTypo);
	}
}
