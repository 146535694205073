import { NgModule } from "@angular/core";
import { LottieModule } from "ngx-lottie";
import { LoaderAnimComponent } from "./loader-anim.component";

@NgModule({
	declarations: [LoaderAnimComponent],
	imports: [LottieModule],
	exports: [LoaderAnimComponent],
})

export class LoaderAnimModule { }
