import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from '../../../authentification/shared/services/auth.service';
import { of as observableOf, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import 'rxjs/Rx';
import { HttpClient } from '@angular/common/http';


@Injectable()

export class RoleGuardService implements CanActivate {

	constructor(public auth: AuthService, public router: Router) {

	}

	// Test des roles
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		// if (!this.auth.user) {
		// 	return false;
		// }
		const canPass = route.data.expectedRole.find((p) => {
			return this.auth.roles.indexOf(p) !== -1;
		})
		if (!this.auth.isAuthenticated()) {
			this.router.navigate(['']);
			return false;
		} else {
			return true
		}
	}
}

