import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MonthYearDatepickerComponent } from './month-year-datepicker/month-year-datepicker.component';
import { DayMonthYearDatepickerComponent } from './day-month-year-datepicker/day-month-year-datepicker.component';


@NgModule({
	declarations: [
		MonthYearDatepickerComponent,
		DayMonthYearDatepickerComponent,
	],
	imports: [
		CommonModule,
		MatDatepickerModule,
		MatFormFieldModule,
		ReactiveFormsModule,
		MatInputModule
	],
	exports: [
		MonthYearDatepickerComponent,
		DayMonthYearDatepickerComponent
	],
})
export class DatepickerModule { }
