import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { CompanyInformations } from '../../models/devis/companyInformations.service';
import { CabinetExtranetService } from '../cabinet.service';
import { ExtranetRoutingService } from '../extranet-routing.service';

@Injectable({
	providedIn: 'root'
})
export class UserExtranetService {

	private baseUrl: String = environment.apiCoortyassurUrl + '/';

	public userType$: BehaviorSubject<number> = new BehaviorSubject(null);
	public id$: BehaviorSubject<number> = new BehaviorSubject(null);
	public firstname$: BehaviorSubject<string> = new BehaviorSubject('');

	// OAUTH USER (SPECIAL YEET)
	public emailUserOAuth$: BehaviorSubject<string> = new BehaviorSubject('');
	public codeMailOAuth$: BehaviorSubject<string> = new BehaviorSubject('');
	public drivequantOAuth$: BehaviorSubject<boolean> = new BehaviorSubject(false);

	// From Coorty
	static userSubject = new BehaviorSubject<any>(null);
	public user: any = null;
	public roles: any = [];

	constructor(
		private _http: HttpClient,
		private _cabinetService: CabinetExtranetService,
		private _routingService: ExtranetRoutingService

	) {}

	public getUserType(): number {
		return this.userType$.value;
	}

	public setUserType(type: number): void {
		this.userType$.next(type);
	}

	public getUserId(): number {
		return this.id$.value;
	}

	public setUserId(id: number): void {
		this.id$.next(id);
	}

	public getUserName(): string {
		return this.firstname$.value;
	}

	public setUserName(name: string): void {
		this.firstname$.next(name);
	}

	// OAUTH USER (SPECIAL YEET)
	public setEmailUserOAuth(email: string): void {
		this.emailUserOAuth$.next(email);
	}
	public getEmailUserOAuth(): string {
		return this.emailUserOAuth$.value;
	}
	public setCodeMailOAuth(code: string): void {
		this.codeMailOAuth$.next(code);
	}
	public getCodeMailOAuth(): string {
		return this.codeMailOAuth$.value;
	}
	public setDrivequantOAuth(value: boolean): void {
		this.drivequantOAuth$.next(value);
	}
	public getDrivequantOAuth(): boolean {
		return this.drivequantOAuth$.value;
	}


	public registerCompanyInformations(userId: number, params: CompanyInformations): Observable<any> {
		return this._http.patch<any>(this.baseUrl + `extranet/utilisateur/${userId}`, params);
	}

	public getDocumentsInfosByUser(userId: number): Observable<any> {
		return this._http.get<any>(this.baseUrl + `extranet/ged/utilisateur/${userId}`);
	}


	// REQUESTS FROM COORTY

	// Recupere le /me
	public getUser() {
		switch (UserExtranetService.userSubject.value) {
			case null:
				return Observable.from(this._http.get(this.baseUrl + 'utilisateur/moi')).do((res: any) => {
					UserExtranetService.userSubject.next(res);
					this.user = res;
					this.setUserName(res.prenom);

					this.roles = this.user.roles.map((prop) => {
						return prop.libelle;
					});

					this._cabinetService.setCabinetSlug(this.user.cabinets[0].slug);
				});

			default:
				this.roles = this.user.roles.map((prop) => {
					return prop.libelle;
				});
				return UserExtranetService.userSubject;
		}
	}

}

export const USER_TYPE_EXTRANET = 19;
