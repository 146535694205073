import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of as observableOf, Observable, BehaviorSubject } from 'rxjs';
import { delay } from 'rxjs/operators';
import 'rxjs/add/operator/do';
import { environment } from 'environments/environment';
import 'rxjs/add/observable/from';
import { CookieService } from 'angular2-cookie';
import { CabinetService } from 'app/coortyapp/shared/services/cabinet.service';
import { ProfilService } from 'app/coortyapp/shared/services/profil.service';

@Injectable()
export class AuthService {
	static userSubject = new BehaviorSubject<any>(null);
	public environnement = new BehaviorSubject<'LTS' | 'RCT'>('LTS');

	public user: any = null;
	public roles: any = [];
	public authTokenNew: string = 'new';
	public currentToken: string = null;
	public refreshToken: string = null;

	// Avatar
	public avatars: any[] = [];
	public avatarId: number;
	public avatarSource: string = null;

	public baseUrl: string = environment.apiCoortyassurUrl + '/';

	constructor(
		private http: HttpClient,
		private cookieService: CookieService,
		private cabinetService: CabinetService,
		private profilService: ProfilService,
	) {
		if (localStorage.getItem('jwt')) {
			this.currentToken = JSON.parse(localStorage.getItem('jwt')).token;
			this.refreshToken = JSON.parse(localStorage.getItem('jwt')).refresh_token;
		}
	}

	// Recupere le /me
	public getUser() {
		return Observable.from(this.http.get(this.baseUrl + 'utilisateur/moi')).do((res: any) => {
			AuthService.userSubject.next(res);
			this.user = res;

			if (res.document_serveurs?.length > 0) {
				this.avatarSource = res.document_serveurs[0].chemin;
			};
			
			this.roles = this.user.roles.map((prop) => {
				return prop.libelle;
			});

			let cabinetsArrayId = res.cabinets.map((cabinet) => cabinet.id);
			this.cabinetService.setCabinetsOfUser(cabinetsArrayId);
		});
	}

	// Verifie l'authentification
	public isAuthenticated(): boolean {
		return this.currentToken ? true : false;
	}

	public logout() {
		this.currentToken = null;
		localStorage.clear();
		sessionStorage.clear();
		this.cookieService.removeAll();
		window.location.reload();
	}

	// Recupere l'url avec l'id du mail qui a été envoyé par l'api
	public pirateAccount(token: String): Observable<String> {
		return this.http.get<String>(this.baseUrl + 'auth/motdepasse/pirate/' + token)
	}

	// Connexion + set token dans le Local Storage
	public signin(email: String, password: String, captcha: String): Observable<String> {    // captcha string
		const query = {
			'email': email,
			'mot_de_passe': password,
			'g-recaptcha-response': captcha,
		}
		return this.http.post<String>(this.baseUrl + 'auth/login_check', query).do((token: any) => {
			this.setTokens(token);
		});
	}

	// Renvoie sur le page mot de passe oublié
	public forgetPassword(email: String, captcha: string): Observable<String> {
		return this.http.post<String>(this.baseUrl + 'auth/motdepasse/perdu', { email, 'g-recaptcha': captcha });
	}

	// Création du nouveau password
	public newPassword(id: String, password: String, captcha: String): Observable<String> {
		const query = {
			'mot_de_passe': password,
			'g-recaptcha': captcha
		}
		return this.http.put<String>(this.baseUrl + 'auth/motdepasse/nouveau/' + id, query);
	}

	// Renvoie le token courant
	public getAuthToken() {
		return this.currentToken;
	}

	// Interceptor ******* // Recupere un nouveau token (refresh)
	public newRefreshToken(): Observable<String> {
		const query = {
			'refresh_token': this.refreshToken
		}
		this.http.post(this.baseUrl + 'auth/token/refresh', query)
			.subscribe((newtoken) => {
				this.setTokens(newtoken);
			})
		return observableOf(this.authTokenNew).pipe(delay(1000));
	}

	// Set tokens dans le Local Storage
	public setTokens(token) {
		localStorage.setItem('jwt', JSON.stringify(token));
		this.refreshToken = token.refresh_token;
		this.currentToken = token.token;
		sessionStorage.clear();
		this.cookieService.removeAll();
	}

	public setEnvironnementSubject(env: 'LTS' | 'RCT'): void {
		this.environnement.next(env);
	}

	public isEnvironnementProduction(): boolean {
		return this.environnement.value === 'RCT' ? false : true;
	}
}
