import { Injectable } from '@angular/core';
import { IdCabinetsList } from 'app/coortyapp/shared/enum/cabinet/id-cabinets-list.enum';
import { CabinetsExtranetService } from 'app/core/services/extranet/cabinets-extranet.service';
import { log } from 'console';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ColorExtranetProService } from './color.service';
import { TypoExtranetProService } from './typo.service';

@Injectable({
	providedIn: 'root'
})
export class CabinetExtranetProService {

	public logo$: BehaviorSubject<string> = new BehaviorSubject('assets/images/logo.jpg');
	public name$: BehaviorSubject<string> = new BehaviorSubject('coorty');
	public id$: BehaviorSubject<number> = new BehaviorSubject(1);
	private slug$: BehaviorSubject<string> = new BehaviorSubject('');
	private droitExtranetPro$: BehaviorSubject<boolean> = new BehaviorSubject(false);

	constructor(
		private _colorService: ColorExtranetProService,
		private _typoService: TypoExtranetProService,
		private _cabinetsExtranetService: CabinetsExtranetService
	) {}

	// Set all cabinet's colors and typos in the CSS
	public getCabinetExtranetProDetails(): void {
		this._colorService.getExtranetColors();
		this._typoService.getExtranetTypos();
	}

	public setCabinetExtranetProDetails(primaryColor: string, secondaryColor: string, primaryTypo: string, secondaryTypo: string): void {
		this._colorService.setExtranetColors(primaryColor, secondaryColor);
		this._typoService.setExtranetTypos(primaryTypo, secondaryTypo);
	}

	public getCabinetLogo(): string {
		return this.logo$.value;
	}

	public setCabinetLogo(logo: string): void {
		this.logo$.next(logo);
	}

	public getCabinetName(): string {
		return this.name$.value;
	}

	public setCabinetName(name: string): void {
		this.name$.next(name);
	}

	public getCabinetId(): number {
		return this.id$.value;
	}

	public setCabinetId(id: number): void {
		this.id$.next(id);
	}

	public getCabinetSlug(): string {
		return this.slug$.value;
	}

	public setCabinetSlug(slug: string): void {
		this.slug$.next(slug);
	}

	public getCabinetDroitExtranetPro(): boolean {
		return this.droitExtranetPro$.value;
	}

	public setCabinetDroitExtranetPro(droitExtranetPro: boolean): void {
		this.droitExtranetPro$.next(droitExtranetPro);
	}


	public isAllowedToExtranetPro(cabinetSlug: any = ''): Observable<boolean> {
		let formattedSlug: string = cabinetSlug.toLowerCase().replaceAll(' ', '');

		return this._cabinetsExtranetService.getCabinetsOpenToExtranet().pipe(
			switchMap((listCabinets: any[]) => {
				let cabinetAllowed = listCabinets.find((cabinet) => cabinet.slug === formattedSlug && cabinet.droitExtranetPro === true);
				let colorPrimary = cabinetAllowed.colorPrimary ? cabinetAllowed.colorPrimary : '1, 1, 1';
				let colorSecondary = cabinetAllowed.colorSecondary ? cabinetAllowed.colorSecondary : '1, 1, 1';

				if(cabinetAllowed) {					
					this.setCabinetExtranetProDetails(
						colorPrimary,
						colorSecondary,
						cabinetAllowed.policePrimary,
						cabinetAllowed.policeSecondary
					);
					this.setCabinetLogo(cabinetAllowed?.documentServeur?.chemin);
					this.setCabinetName(cabinetAllowed.nom);
					this.setCabinetId(cabinetAllowed.id);
					this.setCabinetSlug(cabinetAllowed.slug);
					this.setCabinetDroitExtranetPro(cabinetAllowed?.droitExtranetPro)
				}

				return cabinetAllowed ? of(true) : of(false);
			})
		);
	}

	// CABINETS SPE

	public isCabinetKolibri(): boolean {
		return this.getCabinetId() === IdCabinetsList.KOLIBRI;
	}

	public isCabinetJdgUnit(): boolean {
		return this.getCabinetId() === IdCabinetsList.JDG_UNIT;
	}

	public isCabinetJdgDuval(): boolean {
		return this.getCabinetId() === IdCabinetsList.JDG_DUVAL;
	}

	public isCabinetJdgYusen(): boolean {
		return this.getCabinetId() === IdCabinetsList.JDG_YUSEN;
	}

	public isCabinetSpecial(): boolean {
		let cabinetsSpeArray: number[] = [
			IdCabinetsList.JDG_UNIT,
			IdCabinetsList.KOLIBRI,
			IdCabinetsList.JDG_DUVAL,
			IdCabinetsList.JDG_YUSEN
		];

		return cabinetsSpeArray.includes(this.getCabinetId());
	}
}
