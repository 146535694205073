
import { Injectable } from '@angular/core';

@Injectable()
export class MinutesService {
	getMinutes() {
		const MINUTES: any = [
			{ libelle: '0', minute: 0 },
			{ libelle: '05', minute: 5 },
			{ libelle: '10', minute: 10 },
			{ libelle: '15', minute: 15 },
			{ libelle: '20', minute: 20 },
			{ libelle: '25', minute: 25 },
			{ libelle: '30', minute: 30 },
			{ libelle: '35', minute: 35 },
			{ libelle: '40', minute: 40 },
			{ libelle: '45', minute: 45 },
			{ libelle: '50', minute: 50 },
			{ libelle: '55', minute: 55 },
		]
		return MINUTES;
	}
}

