import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import 'rxjs/add/observable/of';
import { BehaviorSubject } from 'rxjs';
import { HtmlParser } from '@angular/compiler';

@Injectable({
	providedIn: 'root'
})
export class ProfilService {

	static avatarSubject = new BehaviorSubject<any>(null);
	public timeToRelist: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public baseUrl: String = environment.apiCoortyassurUrl + '/';

	public avatars: any = null;
	public avatarId: number;
	public avatar: any;

	constructor(
		private http: HttpClient, private router: Router
	) {}

	public modifyUtilisateurDetail(params) {
		return this.http.put<any>(this.baseUrl + 'utilisateur/moi/modifier', params)
	}

	// CRUD AVATAR
	public createAvatarDocumentServeur(idUser: number, documentServeurType: string, params: any): Observable<any> {
		return this.http.post<any>(this.baseUrl + `utilisateur/${idUser}/documentServeur/${documentServeurType}/create`, params)
	}
	
	public getAllAvatarDocumentServeur(idUser: number) {
		return this.http.get<any>(this.baseUrl + `utilisateur/${idUser}/documentServeur`)
	}

	public updateAvatarDocumentServeur(idUser: number, documentServeurId: number, documentServeurType: string, params: any) {
		return this.http.put<any>(this.baseUrl + `utilisateur/${idUser}/documentServeur/${documentServeurType}/${documentServeurId} `, params)
	}

	public deleteAvatarDocumentServeur(documentServeurId: number) {
		return this.http.delete<any>(this.baseUrl + `documentServeur/${documentServeurId}`)
	} 

	public newUtilisateur(params) {
		return this.http.post<any>(this.baseUrl + 'utilisateur/creer', params)
	}

	public getListCabinets() {
		return this.http.get<any>(this.baseUrl + 'utilisateur/lister/select')
	}

	public newPassword(password: any): Observable<String> {
		return this.http.put<String>(this.baseUrl + 'utilisateur/moi/motdepasse/nouveau', password);
	}

	// AGENDA CALENDAR
	public modifyAccount(params: any): Observable<String> {
		return this.http.put<any>(this.baseUrl + 'utilisateur/moi/mailcalendrier/modifier', params);
	}

	public getAutorisationCheck(): Observable<Boolean> {
		return this.http.get<Boolean>(this.baseUrl + 'utilisateur/moi/mailcalendrier/autorisation/ok');
	}

	public getAutorisationLink(): Observable<any> {
		return this.http.get<any>(this.baseUrl + 'utilisateur/moi/mailcalendrier/autorisation');
	}

	public deleteAutorisation(password): Observable<String> {
		return this.http.put<any>(this.baseUrl + 'utilisateur/moi/mailcalendrier/autorisation/supprimer', password);
	}

	public getListUser(): Observable<any> {
		return this.http.get<any>(this.baseUrl + 'utilisateur/moi/mailcalendrier/partage/lister/select');
	}

	public SharedUser(params): Observable<any> {
		return this.http.put<any>(this.baseUrl + 'utilisateur/moi/mailcalendrier/partage', params);
	}

	// NEW OPTIMAX
	// provider 'microsoft' | 'google' only
	public createOptimaxAccount(params: { email: string, provider: string }): Observable<any> {
		return this.http.post<any>(this.baseUrl + 'utilisateur/optimax/accounts', params);
	}

	public deleteOptimaxAccount(): Observable<any> {
		return this.http.delete<any>(this.baseUrl + 'utilisateur/optimax/accounts');
	}

	public refreshOptimaxAccount(): Observable<any> {
		return this.http.get<any>(this.baseUrl + 'utilisateur/optimax/accounts/refresh');
	}

	public checkStatusOptimaxAccount(): Observable<any> {
		return this.http.get<any>(this.baseUrl + 'utilisateur/optimax/accounts/check-status');
	}
}
