import { throwError as observableThrowError, Observable, BehaviorSubject } from 'rxjs';
import { take, filter, catchError, switchMap, finalize } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpSentEvent, HttpHeaderResponse, HttpProgressEvent, HttpResponse, HttpUserEvent, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthService } from '../../authentification/shared/services/auth.service';
import { environment } from 'environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

	authService: AuthService;
	isRefreshingToken: Boolean = false;
	tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

	constructor(private injector: Injector, private router: Router) { }

	private _refreshHeader(req: HttpRequest<any>, token: any, ): HttpRequest<any> {
		const header = { 'Content-Type': 'application/json' };
		if (token) {
			header['Authorization'] = `Bearer ${token}`;
		}
		req = req.clone({
			setHeaders: header
		});
		return req;
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
		this.authService = this.injector.get(AuthService);
		return next.handle(this._refreshHeader(req, this.authService.getAuthToken())).pipe(
			catchError(error => {
				if (error instanceof HttpErrorResponse && error.error?.code === 401 && error.error?.message === 'Expired JWT Token') {
					return this.handleJWTExpiredTokenError(req, next);
				}
				if ((error.error?.code === 401 && error.error?.message === 'Invalid JWT Token') || error.error?.code === 418
					// || (error.status === 502 && environment.production)
				) {
					return this.logoutUser();
				}
				return observableThrowError(error);
			}));
	}

	handleJWTExpiredTokenError(req: HttpRequest<any>, next: HttpHandler) {
		if (!this.isRefreshingToken) {
			this.isRefreshingToken = true;

			// Reset here so that the following requests wait until the token
			// comes back from the refreshToken call.
			this.tokenSubject.next(null);

			const authService = this.injector.get(AuthService);

			return authService.newRefreshToken().pipe(
				switchMap((newToken: any) => {
					if (newToken) {
						this.tokenSubject.next(newToken);
						return next.handle(this._refreshHeader(this.getNewRequest(req), this.authService.currentToken));
					}
					// If we don't get a new token, we are in trouble so logout.
					return this.logoutUser();
				}),
				catchError(() => {
					// If there is an exception calling 'refreshToken', bad news so logout.
					return this.logoutUser();
				}),
				finalize(() => {
					this.isRefreshingToken = false;
				}));
		} else {
			return this.tokenSubject.pipe(
				filter(token => token != null),
				take(1),
				switchMap(token => {
					return next.handle(this._refreshHeader(this.getNewRequest(req), token));
				}));
		}
	}

	getNewRequest(req: HttpRequest<any>): HttpRequest<any> {
		return req;
	}

	logoutUser() {
		this.authService.logout()
		localStorage.clear();
		this.router.navigateByUrl('auth/login');
		document.location.reload();
		return observableThrowError('');
	}
}
