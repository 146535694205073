import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../../../authentification/shared/services/auth.service';
import { Observable } from 'rxjs/Rx';
import { of } from 'rxjs';

@Injectable()
export class UserResolver implements Resolve<any> {
	constructor(private authService: AuthService) { }

	// Resolver permettant de recuperer le /me
	resolve() {
		return (this.authService.user) ? Observable.of(this.authService.user) : this.authService.getUser();
	}
}
