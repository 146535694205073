import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { LottieModule } from 'ngx-lottie';
import { AlertModalComponent } from './alert-modal.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@NgModule({
	declarations: [
		AlertModalComponent
	],
	imports: [
		CommonModule,
		LottieModule,
		MatButtonModule,
		MatFormFieldModule,
		MatInputModule
	],
	exports: [
		AlertModalComponent
	],
})
export class AlertModalModule { }
