import "@angular/compiler";
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment, loadEnvironment } from 'environments/environment';
import { AppModule } from 'app/app.module';

loadEnvironment().then(() => {

	if (environment.production) {
		enableProdMode();
	}

	platformBrowserDynamic().bootstrapModule(AppModule);

});

