
import { Injectable } from '@angular/core';
import { gridSize } from '@swimlane/ngx-charts';

@Injectable()
export class ColorsAgendaSharedService {
	getColors() {

		const COLORS: any = ['#5fe2b4',
			{ bgcolor: '#e280e5', color: '#fff' },
			{ bgcolor: '#fccabf', color: '#3d405e' },
			{ bgcolor: '#95dfe2', color: '#3d405e' },
			{ bgcolor: '#4bf429', color: '#3d405e' },
			{ bgcolor: '#71ed94', color: '#3d405e' },
			{ bgcolor: '#84ce39', color: '#3d405e' },
			{ bgcolor: '#e2a9f2', color: '#fff' },
			{ bgcolor: '#f466cc', color: '#fff' },
			{ bgcolor: '#09517a', color: '#fff' },
			{ bgcolor: '#d8d634', color: '#3d405e' },
			{ bgcolor: '#8a64db', color: '#fff' },
			{ bgcolor: '#3d3da5', color: '#fff' },
			{ bgcolor: '#298093', color: '#fff' },
			{ bgcolor: '#ff77e8', color: '#fff' },
			{ bgcolor: '#b4d65e', color: '#3d405e' },
			{ bgcolor: '#d16be5', color: '#fff' },
			{ bgcolor: '#ee53fc', color: '#fff' },
			{ bgcolor: '#b6baef', color: '#fff' },
			{ bgcolor: '#91ead7', color: '#3d405e' },
			{ bgcolor: '#f4008a', color: '#fff' },
			{ bgcolor: '#9ae874', color: '#3d405e' },
			{ bgcolor: '#76f2ac', color: '#3d405e' },
			{ bgcolor: '#f293b4', color: '#fff' },
			{ bgcolor: '#e8f9a4', color: '#3d405e' },
			{ bgcolor: '#f4bdb7', color: '#3d405e' },
			{ bgcolor: '#e55947', color: '#fff' },
			{ bgcolor: '#5e8cd1', color: '#fff' },
			{ bgcolor: '#d1ff1c', color: '#3d405e' },
			{ bgcolor: '#f9487d', color: '#fff' },
			{ bgcolor: '#1a60a3', color: '#fff' },
			{ bgcolor: '#c15241', color: '#fff' },
			{ bgcolor: '#e8277e', color: '#fff' },
			{ bgcolor: '#5793c1', color: '#fff' },
			{ bgcolor: '#5b77cc', color: '#fff' },
			{ bgcolor: '#35d66b', color: '#fff' },
			{ bgcolor: '#ffc9d6', color: '#3d405e' },
			{ bgcolor: '#aad35d', color: '#fff' },
			{ bgcolor: '#a9f9d3', color: '#3d405e' },
			{ bgcolor: '#ea75e4', color: '#fff' },
			{ bgcolor: '#c41b6a', color: '#fff' },
			{ bgcolor: '#b3ffb2', color: '#3d405e' },

			// '#ea07d0', '#d36a23', '#d64f77', '#deffad', '#12518c', '#bdf4f9', '#319dc4'
		]
		return COLORS;
	}
}

// #3d405e gris
// #fff

