import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from '../../../authentification/shared/services/auth.service';
import { of as observableOf, Observable } from 'rxjs';
import 'rxjs/add/observable/of';

@Injectable()
export class AntiAuthGuard implements CanActivate {

	constructor(private authService: AuthService, private router: Router) { }

	// Test de l'Authentification pour bloquer les routes
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		if (!this.authService.isAuthenticated()) {
			return true;
		} else {
			this.router.navigate(['']);
		}
	}
}
