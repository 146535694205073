import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { NavigationStart, Router, NavigationEnd } from '@angular/router';
import { CabinetExtranetService } from 'app/extranet/shared/services/cabinet.service';
import { ExtranetRoutingService } from '../extranet/shared/services/extranet-routing.service';

interface ExtranetDomain {
	cabinet: string;
	urls: string[];
}

@Injectable()
export class AppInitService {

	public extranetDomains: ExtranetDomain[] = [
		{
			cabinet: 'kolibri',
			urls: [
				//'https://ngserve.coortyassur.fr',
				'https://rct.kolibri-assurances.fr',
				'https://extranet.kolibri-assurances.com'
			]
		},
		{
			cabinet: 'yeet-vtc',
			urls: [
				//'https://dev.coortyassur.fr',
				'https://rct.yeet-vtc.com',
				'https://extranet.yeet-vtc.com'
			]
		}
	]

	constructor(
		private router: Router,
		private cabinetService: CabinetExtranetService,
		private extranetRoutingService: ExtranetRoutingService
	) {}

	public init() {
		//this.debugRouting();
		const cabinetSlug = this.cabinetService.getCabinetSlug();
		this.extranetRoutingService.baseUrl = 'extranet/' + cabinetSlug;

		for (let domain of this.extranetDomains) {
			if (domain.urls.includes(environment.appUrl)) {
				this.rewriteRoutingForExtranetAsRoot();
				this.cabinetService.setCabinetSlug(domain.cabinet);
				//this.router.navigateByUrl(this.extranetRoutingService.baseUrl + '/auth/login');  // Toi là, va ***** ta maman !
			}
		}
	}

	// AU CAS OU CA PEUT RESSERVIR
	// this._cabinetsExtranetService.getCabinetsOpenToExtranet().subscribe((cabinetsAllowedList) => {

	// 	const cabinetSlug = this._cabinetService.getCabinetSlug();
	// 	this._extranetRoutingService.baseUrl = 'extranet/' + cabinetSlug;

	// 	for (let domain of this.extranetDomains) {
	// 		if (domain.urls.includes(environment.appUrl)) {
	// 			this.rewriteRoutingForExtranetAsRoot();

	// 			let cabinetAllowed = cabinetsAllowedList.find((cabinet) => cabinet.id === domain.cabinetId);
	// 			this._cabinetService.setCabinetSlug(cabinetAllowed?.slug);
	// 			//this._router.navigateByUrl(this._extranetRoutingService.baseUrl + '/auth/login');  // Toi là, va ***** ta maman !
	// 		}
	// 	}
	// });

	private rewriteRoutingForExtranetAsRoot(): void {
		const pathsToKeep = ['erreur', 'impression', 'extranet', 'extranet/:cabinet'];
		const extranetRoutes = this.router.config.filter(route => pathsToKeep.includes(route.path));

		for (let route of extranetRoutes) {
			if (route.path === 'extranet/:cabinet') {
				route.path = '';
			}
		}

		this.router.resetConfig(extranetRoutes);
		this.extranetRoutingService.baseUrl = '';
	}

	private debugRouting(): void {
		if (environment.env === 'RCT') {
			this.router.events.subscribe(event => {
				if (event instanceof NavigationStart) console.log('navigation', event.url);
			});
		}
	}

}
