
import { Injectable } from '@angular/core';

@Injectable()
export class HoursEventService {
	getHours() {
		return [
			{
				'id': 0,
				'libelle': '00:00',
				'hour': 0,
				'minute': 0
			},
			{
				'libelle': '00:30',
				'hour': 0,
				'minute': 30
			},
			{
				'id': 1,
				'libelle': '1:00',
				'hour': 1,
				'minute': 0
			},
			{
				'libelle': '1:30',
				'hour': 1,
				'minute': 30
			},
			{
				'id': 2,
				'libelle': '2:00',
				'hour': 2,
				'minute': 0
			},
			{
				'libelle': '2:30',
				'hour': 2,
				'minute': 30
			},
			{
				'id': 3,
				'libelle': '3:00',
				'hour': 3,
				'minute': 0
			},
			{
				'libelle': '3:30',
				'hour': 3,
				'minute': 30
			},
			{
				'id': 4,
				'libelle': '4:00',
				'hour': 4,
				'minute': 0
			},
			{
				'libelle': '4:30',
				'hour': 4,
				'minute': 30
			},
			{
				'id': 5,
				'libelle': '5:00',
				'hour': 5,
				'minute': 0
			},
			{
				'libelle': '5:30',
				'hour': 5,
				'minute': 30
			},
			{
				'id': 6,
				'libelle': '6:00',
				'hour': 6,
				'minute': 0
			},
			{
				'libelle': '6:30',
				'hour': 6,
				'minute': 30
			},
			{
				'id': 7,
				'libelle': '7:00',
				'hour': 7,
				'minute': 0
			},
			{
				'libelle': '7:30',
				'hour': 7,
				'minute': 30
			},
			{
				'id': 8,
				'libelle': '8:00',
				'hour': 8,
				'minute': 0
			},
			{
				'libelle': '8:30',
				'hour': 8,
				'minute': 30
			},
			{
				'id': 9,
				'libelle': '9:00',
				'hour': 9,
				'minute': 0
			},
			{
				'libelle': '9:30',
				'hour': 9,
				'minute': 30
			},
			{
				'id': 10,
				'libelle': '10:00',
				'hour': 10,
				'minute': 0
			},
			{
				'libelle': '10:30',
				'hour': 10,
				'minute': 30
			},
			{
				'id': 11,
				'libelle': '11:00',
				'hour': 11,
				'minute': 0
			},
			{
				'libelle': '11:30',
				'hour': 11,
				'minute': 30
			},
			{
				'id': 12,
				'libelle': '12:00',
				'hour': 12,
				'minute': 0
			},
			{
				'libelle': '12:30',
				'hour': 12,
				'minute': 30
			},
			{
				'id': 13,
				'libelle': '13:00',
				'hour': 13,
				'minute': 0
			},
			{
				'libelle': '13:30',
				'hour': 13,
				'minute': 30
			},
			{
				'id': 14,
				'libelle': '14:00',
				'hour': 14,
				'minute': 0
			},
			{
				'libelle': '14:30',
				'hour': 14,
				'minute': 30
			},
			{
				'id': 15,
				'libelle': '15:00',
				'hour': 15,
				'minute': 0
			},
			{
				'libelle': '15:30',
				'hour': 15,
				'minute': 30
			},
			{
				'id': 16,
				'libelle': '16:00',
				'hour': 16,
				'minute': 0
			},
			{
				'libelle': '16:30',
				'hour': 16,
				'minute': 30
			},
			{
				'id': 17,
				'libelle': '17:00',
				'hour': 17,
				'minute': 0
			},
			{
				'libelle': '17:30',
				'hour': 17,
				'minute': 30
			},
			{
				'id': 18,
				'libelle': '18:00',
				'hour': 18,
				'minute': 0
			},
			{
				'libelle': '18:30',
				'hour': 18,
				'minute': 30
			},
			{
				'id': 19,
				'libelle': '19:00',
				'hour': 19,
				'minute': 0
			},
			{
				'libelle': '19:30',
				'hour': 19,
				'minute': 30
			},
			{
				'id': 20,
				'libelle': '20:00',
				'hour': 20,
				'minute': 0
			},
			{
				'libelle': '20:30',
				'hour': 20,
				'minute': 30
			},
			{
				'id': 21,
				'libelle': '21:00',
				'hour': 21,
				'minute': 0
			},
			{
				'libelle': '21:30',
				'hour': 21,
				'minute': 30
			},
			{
				'id': 22,
				'libelle': '22:00',
				'hour': 22,
				'minute': 0
			},
			{
				'libelle': '22:30',
				'hour': 22,
				'minute': 30
			},
			{
				'id': 23,
				'libelle': '23:00',
				'hour': 23,
				'minute': 0
			},
			{
				'libelle': '23:30',
				'hour': 23,
				'minute': 30
			},
			{
				'id': 24,
				'libelle': '00:00',
				'hour': 24,
				'minute': 0
			},
		]
	}
}

