import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

export interface Cabinet {
	id: number;
	nom: string;
}

export interface UtilisateursCabinet {
	id: number;
	nom: string;
	prenom: string;
	cabinets: Cabinet[];
}

export interface CabinetListModel {
	cabinet_nom: string;
	cabinet_id: number;
	cabinet_logo?: string;
	cabinet_adresse_adresse?: string;
	cabinet_adresse_code_postale?: string;
	cabinet_adresse_ville?: string;
	cabinet_responsable?: string;
	utilisateurs_cabinet: UtilisateursCabinet[];
}

@Injectable({
	providedIn: 'root'
})
export class CabinetService {

	public timeToRelist: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public cabinetsOfUser$: BehaviorSubject<number[]> = new BehaviorSubject([]);

	private _cabinetId$: BehaviorSubject<number> = new BehaviorSubject(null);

	public baseUrl: String = environment.apiCoortyassurUrl + '/';

	constructor(
		private http: HttpClient,
	) {	}

	public setCabinetId(cabinetId: number): void {
		this._cabinetId$.next(cabinetId);
	}

	public getCabinetId(): number {
		return this._cabinetId$.value;
	}

	public listenToCabinetId(): Observable<number> {
		return this._cabinetId$.asObservable();
	}

	public getCabinetsOfUSer(): number[] {
		return this.cabinetsOfUser$.value;
	}


	public setCabinetsOfUser(arrayId: number[]): void {
		this.cabinetsOfUser$.next(arrayId);
	}

	public getCabinetList() {
		return this.http.get<CabinetListModel[]>(this.baseUrl + 'cabinet/lister');
	}

	public getCabinetModelesEmailList(id) {
		return this.http.get<any[]>(this.baseUrl + 'email/modele/cabinet/' + id);
	}

	public updateCabinetModeleMail(mailId, params: { cabinet: number, favoris: boolean, name: string, contenu: string, signature: string }) {
		return this.http.put<any[]>(this.baseUrl + 'email/modele/' + mailId, params);
	}

	public addCabinetModeleMail(params: { cabinet: number, favoris: boolean, name: string, contenu: string, signature: string }) {
		return this.http.post<any[]>(this.baseUrl + 'email/modele', params);
	}

	public deleteCabinetModeleMail(id) {
		return this.http.delete<any[]>(this.baseUrl + 'email/modele/' + id);
	}

	public getCabinetModelesPDFList(id) {
		return this.http.get<any[]>(this.baseUrl + 'modele/pdf/cabinet/' + id);
	}

	public createDocumentForRenouvellementContrat(params) {
		return this.http.post<any[]>(this.baseUrl + 'document-editor/yeet/quittance', params);
	}

	public updateCabinetModelePDF(id, pdfId, params: { html: string, facture: boolean, factureFavoris: boolean, quittance: boolean, quittanceFavoris: boolean, nom: string, header: string, footer: string }) {
		return this.http.put<any[]>(this.baseUrl + 'cabinet/' + id + '/pdf/' + pdfId, params);
	}

	public addCabinetModelePDF(id, params: { html: string, facture: boolean, factureFavoris: boolean, quittance: boolean, quittanceFavoris: boolean, nom: string, header: string, footer: string }) {
		return this.http.post<any[]>(this.baseUrl + 'cabinet/' + id + '/pdf', params);
	}

	public deleteCabinetModelePdf(id){
		return this.http.delete<any[]>(this.baseUrl +'modele/pdf/'+ id );
	}

	public getAllCabinet() {
		return this.http.get<any>(this.baseUrl + 'cabinet/all');
	}

	// CRUD LOGO CABINET
	public createLogoDocumentServeur(idCabinet: number, documentServeurType: string, params: any): Observable<any> {
		return this.http.post<any>(this.baseUrl + `cabinet/${idCabinet}/documentServeur/${documentServeurType}/create`, params)
	}

	public getAllLogoDocumentServeur(idCabinet: number) {
		return this.http.get<any>(this.baseUrl + `cabinet/${idCabinet}/documentServeur`)
	}

	public updateLogoDocumentServeur(idCabinet: number, documentServeurId: number, documentServeurType: string, params: any) {
		return this.http.put<any>(this.baseUrl + `cabinet/${idCabinet}/documentServeur/${documentServeurType}/${documentServeurId} `, params)
	}

	public deleteLogoDocumentServeur(documentServeurId: number) {
		return this.http.delete<any>(this.baseUrl + `documentServeur/${documentServeurId}`)
	}

	public getCabinetDetail(id) {
		return this.http.get<any>(this.baseUrl + 'cabinet/detail/' + id)
	}

	public getNewCabinetDetail(cabinetId: number): Observable<any> {
		return this.http.get<any>(this.baseUrl + 'cabinet/' + cabinetId);
	}

	public deleteCabinet(id) {
		return this.http.delete<any>(this.baseUrl + 'cabinet/supprimer/' + id)
	}

	public modifyCabinetDetail(params, id: any) {
		return this.http.put<any>(this.baseUrl + 'cabinet/modifier/' + id, params)
	}

	public modifyCabinetElement(params, cabinetId: number): Observable<any> {
		return this.http.patch<any>(this.baseUrl + 'cabinet/' + cabinetId, params);
	}

	public newCabinet(params) {
		return this.http.post<any>(this.baseUrl + 'cabinet/creer', params)
	}

	public getListResponsable(id) {
		return this.http.get<any>(this.baseUrl + 'cabinet/lister/select/' + id)
	}

	public getHistoriqueCabinetGET(id: any, dateDebut: any, dateFin?: any) {
		let date = '/' + dateFin;
		return this.http.get<any>(this.baseUrl + 'historique/cabinet/' + id + '/date/' + dateDebut + date);
	}

	public getHistoriqueUserCabinetGET(id: any, dateDebut: any, dateFin?: any) {
		let date = '/' + dateFin;
		return this.http.get<any>(this.baseUrl + 'historique/cabinet/utilisateur/' + id + '/date/' + dateDebut + date);
	}

	public getContratAlertJours(id: number) {
		return this.http.get<any>(this.baseUrl + 'cabinet/' + id + '/configuration');
	}
	public patchContratAlertJours(params, id: number) {
		return this.http.put<any>(this.baseUrl + 'cabinet/' + id + '/configuration', params);
	}


	// APPEL A COTISATION
		// Ancien
	public getContratsConfierSansAppelByCabinetId(date: string, cabinetId: any) {
		return this.http.get<any>(this.baseUrl + `contrat/confier/sansappel/${date}/cabinet/${cabinetId}`);
	}

}
